// CountersSection.js
import React from 'react';
import CounterUp from './CounterUp';
import './CountersSection.scss';

const CountersSection = () => {
  return (
    <div className="counters-section">
      <CounterUp start={0} end={50} duration={2000} label="Projects Completed" />
      <CounterUp start={0} end={85000} duration={2000} label="Hectare Area" />
      <CounterUp start={0} end={6000} duration={2000} label="Flying Hour" />
      <CounterUp start={0} end={25000} duration={2000} label="Data Collected" />
    </div>
  );
};

export default CountersSection;
