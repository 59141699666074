import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faClock, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './Contact.scss';
import Dots from '../Icons/Dots';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA2BU4xMNSHyJ1f553zaYnGifQJD0Fh3gk",
    authDomain: "vidya-geomatics-website-995c4.firebaseapp.com",
    projectId: "vidya-geomatics-website-995c4",
    storageBucket: "vidya-geomatics-website-995c4.appspot.com",
    messagingSenderId: "323285241562",
    appId: "1:323285241562:web:4f08d23c810a725b92dfe3",
    measurementId: "G-1EGB38SK4G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const Contact = () => {
    const [formData, setFormData] = useState({ name: '', phone: '', email: '', message: '' });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form data
        if (!formData.name || !formData.phone || !formData.email || !formData.message) {
            setErrorMessage('Please fill in all required fields.');
            return;
        }

        // Show the thank you message immediately
        setIsSubmitted(true);

        try {
            // Save data to Firestore
            await addDoc(collection(firestore, "contact"), {
                ...formData,
                timestamp: serverTimestamp(),
            });
            console.log("Form data saved to Firestore");

            // Send email
            await emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_PUBLIC_KEY');
            console.log("Email sent successfully");

            // Clear form data after submission
            setFormData({ name: '', phone: '', email: '', message: '' });
        } catch (error) {
            console.error("Error submitting form:", error);
            setErrorMessage('An error occurred while submitting the form. Please try again later.');
        }
    };

    return (
        <div className="contactView">
            <div className="container">
                <div className="contactForm section">
                    <h2>Contact Us <Dots /></h2>
                    <div className="container-inner">
                        <div className="wrapper">
                            <div className="info">
                                <div className="column">
                                    <div className="heading">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        <h4>Madhya Pradesh</h4>
                                    </div>
                                    <p>Aastha height Lambhakheda</p>
                                    <p>462038</p>
                                    <p>Bhopal, Madhya Pradesh</p>
                                </div>
                                <div className="column">
                                    <div className="heading">
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                        <h4>Contact</h4>
                                    </div>
                                    <p>9425412562 / 9958358349</p>
                                    <p>info@vidyageomatics.com</p>
                                </div>
                                <div className="column">
                                    <div className="heading">
                                        <FontAwesomeIcon icon={faClock} />
                                        <h4>Working time</h4>
                                    </div>
                                    <p>Monday - Friday</p>
                                    <p>9:00 - 17:00</p>
                                </div>
                            </div>

                            <div className="form">
                                {!isSubmitted ? (
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            placeholder="Name"
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            placeholder="Phone"
                                            pattern="[0-9]{10}"
                                            maxLength="10"
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            placeholder="Email"
                                            onChange={handleChange}
                                            required
                                        />
                                        <textarea
                                            name="message"
                                            value={formData.message}
                                            placeholder="Message"
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                        <div className="send">
                                            <button type="submit">
                                                <FontAwesomeIcon icon={faPaperPlane} />
                                                SEND MESSAGE
                                            </button>
                                        </div>
                                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                                    </form>
                                ) : (
                                    <div className="success-message">
                                        <h3>Thank you for contacting us!</h3>
                                        <p>We will get back to you as soon as possible.</p>
                                        <Link to="/contact" onClick={() => window.location.reload()}>Send Another Message</Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
