import React from 'react';
import { FaWhatsapp, FaLinkedin, FaEnvelope, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './Sidebar.scss';
import { NavLink } from 'react-router-dom';

function Sidebar() {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`sidebar-container ${isOpen ? 'open' : 'closed'}`}>
      <div className={`sidebar ${isOpen ? '' : 'hidden'}`}>
        <button onClick={handleToggle} className="toggle-btn">
          <FaArrowLeft />
        </button>
        <NavLink to="/contact" end className={({ isActive }) => isActive ? "active" : ""}>
          <FaEnvelope className="icon-envelope" /> Contact us
        </NavLink>

        <a href="https://wa.me/+919425412562" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp className="icon-whatsapp" />
        </a>
        <a href="https://www.linkedin.com/in/sudeep-kumar-4b9504242/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="icon-linkedin" />
        </a>
      </div>
      {!isOpen && (
        <div className="toggle-sidebar">
          <button onClick={handleToggle} className="toggle-btn">
            <FaArrowRight />
          </button>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
