// CounterUp.js
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
// import '../Components/';

const CounterUp = ({ start, end, duration, label }) => {
  const [count, setCount] = useState(start);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Trigger when 50% of the element is visible
  });

  useEffect(() => {
    if (inView) {
      let startTime = null;

      const animate = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const progress = timestamp - startTime;
        const progressPercentage = Math.min(progress / duration, 1);
        setCount(Math.floor(progressPercentage * (end - start) + start));

        if (progress < duration) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    }
  }, [inView, start, end, duration]);

  return (
    <div ref={ref} className="counter-box">
      <h1>{count}+</h1>
      <p>{label}</p>
    </div>
  );
};

export default CounterUp;
