import React from 'react';

import { NavLink } from 'react-router-dom';

//Styles
import './Footer.scss';

//Icons
// import BackToTop from '../Icons/BackToTop';

function Footer(props) {
    return (
        <div className="footer">
            <div className="container">
                <div className="container-inner">
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17095.132001034362!2d77.39584876629559!3d23.32754970547899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c68b8aa042513%3A0x4cc2400c1e94c6cb!2sAstha%20Height!5e0!3m2!1sen!2sin!4v1726291861657!5m2!1sen!2sin" width="80%" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d21293.917988859735!2d17.859605039550782!3d48.153822399999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ssk!2ssk!4v1605897055710!5m2!1ssk!2ssk" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                    <div className="column-wrapper">
                        <div className="column">
                            <h5>Quick Links</h5>
                            <NavLink to="/" onClick={props.scrollToTop}>Home</NavLink>
                            <NavLink to="/about" onClick={props.scrollToTop}>About Us</NavLink>
                            <NavLink to="/portfolio" onClick={props.scrollToTop}>Service</NavLink>
                            <NavLink to="/contact" onClick={props.scrollToTop}>Contact Us</NavLink>
                            <NavLink to="/rental" onClick={props.scrollToTop}>Rental Service</NavLink>
                        </div>

                        <div className="column">
                            <h5>Contact</h5>
                            <a href="tel:9425412562">+91 9425412562</a>
                            <a href="tel:9958358349">+91 9958358349</a>
                            <a href="mailto:info@vidyageomatics.com">info@vidyageomatics.com</a>
                        </div>

                        <div className="column">
                            <h5>Equipment rental</h5>
                            <a href="tel:9425412562">+91 9425412562</a>
                            <a href="tel:9958358349">+91 9958358349</a>
                            <a href="mailto:info@vidyageomatics.com">info@vidyageomatics.com</a>

                        </div>

                        <div className="column">
                            <h5> Address</h5>
                            <p>Aastha height Lambhakheda</p>
                            <p>462038</p>
                            <p>Bhopal,Madhya Pradesh </p>
                        </div>
                    </div>

                    <div className="copyright">
                        <p>&copy; All Copyright 2024 <strong>Vidyageomatics Solution Pvt. Ltd.</strong></p>

                        {/* <div className="back-to-top">
        <BackToTop scrollToTop={props.scrollToTop} />
        <p onClick={props.scrollToTop}>Back to the top</p> 
    </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;