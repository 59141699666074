import React, { useState, useEffect } from 'react';

// React-router
import { BrowserRouter as Router, Route } from 'react-router-dom';

// React-transition-group
import { CSSTransition } from 'react-transition-group';

// Styles
import './App.scss';

// Components
import Navigation from './Components/Navigation';
import MobileNavigation from './Components/MobileNavigation';
import Footer from './Components/Footer';
import Sidebar from './Components/Sidebar'; // Import the sidebar component

// Views
import Home from './Views/Home';
import About from './Views/About';
import Contact from './Views/Contact';
import Client from './Views/Client';
import Portfolio from './Views/Portfolio';
import Rental from './Views/Rental';
import Quotation from './Views/Quotation';

// Icons
import BackToTop from './Icons/BackToTop';


function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  // Toggle mobile menu
  const openMobileMenu = () => setIsMobileMenuOpen(true);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  // Detect position from top and show/hide scrollToTop button
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Smooth scroll to top
  const smoothScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Router>
      <div className="App">
        <div className="wrapper">

          <Sidebar /> {/* Sidebar component */}
          <CSSTransition
            in={isMobileMenuOpen}
            classNames="mobile-navigation"
            timeout={300}
            unmountOnExit
          >
            <div className="mobile-menu-wrapper">
              <MobileNavigation closeMobileMenu={closeMobileMenu} />
            </div>
          </CSSTransition>

          <CSSTransition
            in={isMobileMenuOpen}
            classNames="mobile-navigation-overlay"
            timeout={300}
            unmountOnExit
          >
            <div className="mobile-menu-overlay" onClick={closeMobileMenu}></div>
          </CSSTransition>

          <Navigation openMobileMenu={openMobileMenu} />

          <Route key="/" exact path="/">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="slide-backward"
                unmountOnExit
              >
                <div className="page">
                  <Home scrollToTop={smoothScrollToTop}/>
                </div>
              </CSSTransition>
            )}
          </Route>

          <Route key="/about" exact path="/about">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="slide-forward"
                unmountOnExit
              >
                <div className="page">
                  <About scrollToTop={smoothScrollToTop}/>
                </div>
              </CSSTransition>
            )}
          </Route>

          <Route key="/contact" exact path="/contact">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="slide-forward"
                unmountOnExit
              >
                <div className="page">
                  <Contact scrollToTop={smoothScrollToTop}/>
                </div>
              </CSSTransition>
            )}
          </Route>

          <Route key="/portfolio" exact path="/portfolio">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="slide-forward"
                unmountOnExit
              >
                <div className="page">
                  <Portfolio scrollToTop={smoothScrollToTop}/>
                </div>
              </CSSTransition>
            )}
          </Route>

          <Route key="/client" exact path="/client">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="slide-forward"
                unmountOnExit
              >
                <div className="page">
                  <Client scrollToTop={smoothScrollToTop}/>
                </div>
              </CSSTransition>
            )}
          </Route>

          <Route key="/quotation" exact path="/quotation">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="slide-forward"
                unmountOnExit
              >
                <div className="page">
                  <Quotation scrollToTop={smoothScrollToTop}/>
                </div>
              </CSSTransition>
            )}
          </Route>

          <Route key="/rental" exact path="/rental">
            {({ match }) => (
              <CSSTransition
                in={match != null}
                timeout={300}
                classNames="slide-forward"
                unmountOnExit
              >
                <div className="page">
                  <Rental scrollToTop={smoothScrollToTop}/>
                </div>
              </CSSTransition>
            )}
          </Route>

          <Footer />

          {/* Back to Top Button */}
          {showScrollToTop && (
            <div className="scrollToTop" onClick={smoothScrollToTop}>
              <BackToTop scrollToTop={smoothScrollToTop}/>
            </div>
          )}
        </div>
      </div>
    </Router>
  );
}

export default App;



