import React, { useState, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt, faClock, faPaperPlane, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import emailjs from 'emailjs-com';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';

// Styles
import './Home.scss';

// Components
import Carousel from '../Components/Carousel';
import CountersSection from '../Components/CountersSection';
// import Dots from '../Icons/Dots';

// Firebase configuration (use your existing Firebase config)
const firebaseConfig = {
    apiKey: "AIzaSyA2BU4xMNSHyJ1f553zaYnGifQJD0Fh3gk",
    authDomain: "vidya-geomatics-website-995c4.firebaseapp.com",
    projectId: "vidya-geomatics-website-995c4",
    storageBucket: "vidya-geomatics-website-995c4.appspot.com",
    messagingSenderId: "323285241562",
    appId: "1:323285241562:web:4f08d23c810a725b92dfe3",
    measurementId: "G-1EGB38SK4G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

function Home(props) {
    const [formData, setFormData] = useState({ name: '', phone: '', email: '', message: '' });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form data
        if (!formData.name || !formData.phone || !formData.email || !formData.message) {
            setErrorMessage('Please fill in all required fields.');
            return;
        }

        // Show the thank you message immediately
        setIsSubmitted(true);

        try {
            // Save data to Firestore
            await addDoc(collection(firestore, "contact"), {
                ...formData,
                timestamp: serverTimestamp(),
            });
            console.log("Form data saved to Firestore");

            // Send email
            await emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_PUBLIC_KEY');
            console.log("Email sent successfully");

            // Clear form data after submission
            setFormData({ name: '', phone: '', email: '', message: '' });
        } catch (error) {
            console.error("Error submitting form:", error);
            setErrorMessage('An error occurred while submitting the form. Please try again later.');
        }
    };

    const words = useMemo(() => [
        "Land ",
        "3D Laser ",
        "As-Build ",
        "Drone Mapping",
        "Geo Technical ",
        "Tunnel ",
        "Track "
    ], []);

    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    const [isFadingOut, setIsFadingOut] = useState(false);
    const typingSpeed = 200;
    const fadeOutSpeed = 300;

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsFadingOut(true);
            setTimeout(() => {
                setCurrentWordIndex((prev) => (prev + 1) % words.length);
                setIsFadingOut(false);
                setDisplayedText('');
            }, fadeOutSpeed);
        }, 4000);

        return () => clearTimeout(timeout);
    }, [currentWordIndex, words.length, fadeOutSpeed]);

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (displayedText.length < words[currentWordIndex].length) {
                setDisplayedText((prev) => prev + words[currentWordIndex][displayedText.length]);
            }
        }, typingSpeed);

        return () => clearTimeout(typingTimeout);
    }, [displayedText, currentWordIndex, words, typingSpeed]);


    return (
        <div className="homeView">
            <Carousel />

            <div className="container">
                <div className="rentalOffer">
                    <p>Rental of top technology at best prices</p>
                    <NavLink to="/rental" onClick={() => props.scrollToTop()}>Rental Services</NavLink>
                </div>
                <div className="sentence">
                    <span>Are you looking for </span>
                    <span className={`typed-text ${isFadingOut ? 'fade-out' : 'fade-in'}`}>
                        {displayedText}
                    </span>
                    <span> survey?</span>
                </div>
                <div className="aboutUs section">
                    <h2>About Us</h2>
                    <div className="container-inner">
                        <h4>Vidyageomatics</h4>
                        <div className="text">
                            <h4>"The trusted name for Survey Engineering Solutions"</h4>
                            <p>Vidya Geomatics Solutions is a pioneering Survey Engineering Company in India established in the year of 2019 and the most leading Organization in the field of Land Survey, Mobile Mapping solutions, Drone mapping and laser scanning solutions</p>
                            <div className="showMore">
                                <NavLink to="/about" onClick={() => props.scrollToTop(0, 0)}>Show more <span><FontAwesomeIcon icon={faLongArrowAltRight} /></span></NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <CountersSection />
            </div>

            <div className="allWorks">
                <div className="container">
                    <h3>Want to see what services we provide?</h3>
                    <NavLink to="/portfolio" onClick={() => props.scrollToTop()}>View for details..</NavLink>
                </div>
            </div>





            <div className="contact section">
                <div className="container">
                    <h2>GET IN TOUCH </h2>
                    <div className="container-inner">
                        <div className="wrapper">
                            <div className="info">
                                <div className="column">
                                    <div className="heading">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        <h4>Madhya Pradesh</h4>
                                    </div>
                                    <p>Aastha height Lambhakheda</p>
                                    <p>462038</p>
                                    <p>Bhopal, Madhya Pradesh</p>
                                </div>
                                <div className="column">
                                    <div className="heading">
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                        <h4>Contact</h4>
                                    </div>
                                    <p>9425412562 / 9958358349</p>
                                    <p>info@vidyageomatics.com</p>
                                </div>
                                <div className="column">
                                    <div className="heading">
                                        <FontAwesomeIcon icon={faClock} />
                                        <h4>Working time</h4>
                                    </div>
                                    <p>Monday - Friday</p>
                                    <p>9:00 - 17:00</p>
                                </div>
                            </div>

                            <div className="form">
                                {isSubmitted ? (
                                    <div className="success-message1">
                                        <h3>Thank you for contacting us!</h3>
                                        <p>We will get back to you as soon as possible.</p>
                                        <NavLink to="/contact" onClick={() => window.location.reload()}>Send Another Message</NavLink>
                                    </div>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            placeholder="Name"
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="tel"
                                            name="phone"
                                            value={formData.phone}
                                            placeholder="Phone"
                                            pattern="[0-9]{10}"
                                            maxLength="10"
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            placeholder="Email"
                                            onChange={handleChange}
                                            required
                                        />
                                        <textarea
                                            name="message"
                                            value={formData.message}
                                            placeholder="Message"
                                            onChange={handleChange}
                                            required
                                        ></textarea>
                                        <div className="send">
                                            <button type="submit">
                                                <FontAwesomeIcon icon={faPaperPlane} />
                                                SEND MESSAGE
                                            </button>
                                        </div>
                                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
