import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './Quotation.scss';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA2BU4xMNSHyJ1f553zaYnGifQJD0Fh3gk",
    authDomain: "vidya-geomatics-website-995c4.firebaseapp.com",
    projectId: "vidya-geomatics-website-995c4",
    storageBucket: "vidya-geomatics-website-995c4.appspot.com",
    messagingSenderId: "323285241562",
    appId: "1:323285241562:web:4f08d23c810a725b92dfe3",
    measurementId: "G-1EGB38SK4G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

function Quotation() {
    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        email: '',
        contactNumber: '',
        subject: '',
        projectName: '',
        role: '',
        selectedServices: [],
        requirements: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [submitted, setSubmitted] = useState(false); // State to handle form submission

    const services = [
        "Topographical Survey",
        "Mobile Mapping Services",
        "Construction Survey Services",
        "Gate Level Fixing & Computation",
        "Building Survey Services",
        "Demarcation of Plot Boundaries",
        "Control Network Establishment",
        "Stockpile Volume/Quantity Survey",
        "3D Modeling Services",
        "Reverse Engineering Services",
        "Dilapidation Survey",
        "Bathymetric Survey",
        "Aerial / Drone Related Services",
        "3D Laser Scanning Requirements",
        "Trakhees/Nakheel/DDA Services",
        "As-Built Survey Services",
        "Underground Utility Surveys",
        "Dimension Control Survey Services",
        "Hire Survey Team on Daily/Weekly/Monthly Basis",
        "Concrete Scanning & Drilling Services",
        "Rental of Survey Equipment",
        "Purchase of Brand New Equipment",
        "Soil Investigation Services",
        "Others"
    ];

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData(prevData => ({
                ...prevData,
                selectedServices: checked
                    ? [...prevData.selectedServices, value]
                    : prevData.selectedServices.filter(item => item !== value)
            }));
        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.selectedServices.length === 0) {
            setErrorMessage('Please select at least one service.');
        } else {
            setErrorMessage('');
            try {
                // Save data to Firestore
                await addDoc(collection(firestore, "quotations"), {
                    ...formData,
                    timestamp: serverTimestamp(),
                });
                console.log("Form data saved to Firestore");

                setSubmitted(true); // Update the state to show the thank-you message
                window.scrollTo(0, 0); // Scroll to the top of the page
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        }
    };

    return (
        <div className="Quotation-container">
            <div className="Quotation-slider">
                <div className="Quotation-slider-container">
                    <div className="Quotation-slider-text">
                        <h4>GET IN TOUCH WITH US</h4>
                        <h2>Request For Quotation</h2>
                        <p>We want to work with you for your project. Please fill up the below form to get a quotation for your project.</p>
                    </div>
                </div>
            </div>
            {submitted ? (
                <div className="thank-you-message">
                    <h2>Thank You!</h2>
                    <p>Your request has been submitted successfully.</p>
                    <NavLink to="/" exact activeClassName="active">Go To Home Page</NavLink>
                </div>
            ) : (
                <div className="quotation-form-container">
                    <div className="quotation-form">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                placeholder="Name"
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="companyName">Company Name:</label>
                            <input
                                type="text"
                                id="companyName"
                                name="companyName"
                                value={formData.companyName}
                                placeholder="Company Name"
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="email">Your Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                placeholder="Your Email"
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="contactNumber">Contact Number:</label>
                            <input
                                type="tel"
                                id="contactNumber"
                                name="contactNumber"
                                value={formData.contactNumber}
                                placeholder="Contact Number"
                                pattern="[0-9]{10}"
                                maxLength="10"
                                onChange={handleChange}
                                required
                                title="Please enter a valid 10-digit contact number"
                            />

                            <label htmlFor="subject">Subject:</label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={formData.subject}
                                placeholder="Subject"
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="projectName">Project Name:</label>
                            <input
                                type="text"
                                id="projectName"
                                name="projectName"
                                value={formData.projectName}
                                placeholder="Project Name"
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="role">Select Role:</label>
                            <select
                                id="role"
                                name="role"
                                value={formData.role}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select your role</option>
                                <option value="client">Client/Owner</option>
                                <option value="Main Contractor">Main Contractor</option>
                                <option value="sub-contractor">Sub-Contractor</option>
                                <option value="consultant">Consultant</option>
                                <option value="other">Other</option>
                            </select>

                            {/* Checkbox Group */}
                            <label>Select the service:</label>
                            <div className="checkbox-group">
                                {services.map((service, index) => (
                                    <div className="checkbox-option" key={index}>
                                        <input
                                            type="checkbox"
                                            id={`service-${index}`}
                                            name="services"
                                            value={service}
                                            checked={formData.selectedServices.includes(service)}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor={`service-${index}`}>{service}</label>
                                    </div>
                                ))}
                            </div>

                            {/* Error Message */}
                            {errorMessage && <p className="error-message">{errorMessage}</p>}

                            <label htmlFor="requirements">Describe your Requirements:</label>
                            <textarea
                                id="requirements"
                                name="requirements"
                                value={formData.requirements}
                                placeholder="Message"
                                onChange={handleChange}
                                required
                                style={{ height: '10em' }}
                            ></textarea>
                            <button type="submit"> Submit </button>
                            
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Quotation;
