import React from 'react';

//React-router-dom
import { NavLink } from 'react-router-dom';

//Styles
import './Navigation.scss';


//Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from '../Img/logo.png';


function Navigation(props) {
    return (
        <nav>
            <div className="container">
                <div className="nav-items">
                    <div className="logo">
                        <NavLink to="/">
                            <img src={logo} alt="Company Logo" className="logo-image" />
                        </NavLink>
                    </div>
                    
                    <div className="menu">
                        <ul>
                            <li><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
                            <li><NavLink to="/about" exact activeClassName="active">About Us</NavLink></li>
                            <li><NavLink to="/portfolio" exact activeClassName="active">Service</NavLink></li>
                            <li><NavLink to="/client" exact activeClassName="active">Client</NavLink></li>
                            <li><NavLink to="/rental" exact activeClassName="active">Rental Service</NavLink></li>
                        </ul>
                    </div>

                    <div className="language">
                        <FontAwesomeIcon className="globe" icon={faGlobe} />
                        <a href="https://maps.app.goo.gl/BrADU9aVxErfj9Xs8" className="location-link" target="_blank" rel="noopener noreferrer">
                            <p>INDIA</p>
                        </a>
                        <FontAwesomeIcon className="angleDown" icon={faAngleDown} />
                    </div>

                    <div className="quotation">
                        <NavLink to="/quotation" activeClassName="activeQuotation">Quotation</NavLink>
                    </div>

                    <div className="quotation">
                        <NavLink to="/contact" activeClassName="activeQuotation">Contact Us</NavLink>
                    </div>

                    <div className="mobile-menu-icon" onClick={() => props.openMobileMenu()}>
                        <FontAwesomeIcon icon={faBars} />
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;
