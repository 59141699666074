import React, { useState } from "react";
// Styles
import './Portfolio.scss';
// import land_survey from '../Img/services/land_survey.avif';
// import threeD_survey from '../Img/services/threeD_service.png';
import Dots from '../Icons/Dots';
// import Square from '../Icons/Square';

function Portfolio() {
  // State to manage the open/close status of each dropdown
  const [openDropdown, setOpenDropdown] = useState(null);

  // Function to toggle the dropdown
  const toggleDropdown = (index) => {
    if (openDropdown === index) {
      setOpenDropdown(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdown(index); // Open the clicked dropdown
    }
  };

  return (
    <div className="portfolioView1">
      <div className="container">
        <div className="intro section">
          <h2>Services that we provide <Dots /></h2>
          {/* <Square /> */}
          <div className="container-inner">
            <div className="text">
              {/* <p>VGS employs a full-time team of professionals, allowing us to offer a comprehensive range of surveying services. Our expertise includes boundary and topographicsurveys, construction stake-out, and monitoring.<p/>
              <p>
               We specialize in delivering topographic mapping services, producing both 2D and 3D topographic maps according to specific projection requirements. Our maps detail contours, 3D surface features, roads, relief, and planimetric elements for projects of all scales. Our 3D topographic map delivery includes raster-to-vector conversion and ortho photo feature extraction. We accurately capture features such as building rooftops,contour lines, boundary lines, transportation infrastructure, fences, trees, water bodies, vegetation, habitations, and forest areas.VGS utilizes advanced GIS and CAD mapping software, including AutoCAD and ArcGIS, to extract and process these features.</p> */}
               <p>VGS employs a full-time team of professionals, allowing us to offer a comprehensive range of surveying services. Our expertise includes boundary and topographicsurveys, construction stake-out, and monitoring</p>
               <p>e specialize in delivering topographic mapping services, producing both 2D and 3D topographic maps according to specific projection requirements. Our maps detail contours, 3D surface features, roads, relief, and planimetric elements for projects of all scales. Our 3D topographic map delivery includes raster-to-vector conversion and ortho photo feature extraction. We accurately capture features such as building rooftops,contour lines, boundary lines, transportation infrastructure, fences, trees, water bodies, vegetation, habitations, and forest areas.VGS utilizes advanced GIS and CAD mapping software, including AutoCAD and ArcGIS, to extract and process these features.</p>
              </div>
           </div>
        </div>

        {/* Dropdown section 1 */}
        <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(1)}>
            <h2>Land Surveying and Mapping</h2>
            <span className={`dropdown-arrow ${openDropdown === 1 ? "open" : ""}`}>&#9662;</span>
          </div>
          {openDropdown === 1 && (
            <div className="dropdown-content">
              <div className="dropdown-content-inner">
                <div className="content-left">
                  <p>VGS employs a dedicated team of professionals to deliver a comprehensive range of land surveying services using advanced tools like Total Station, DGPS, Digital Level, and Auto Level. Their services include:
                  </p>
                  <ul>
                    <li>Topographical Surveys</li>
                    <li>Demarcation / Plot Boundary Marking</li>
                    <li>Establishing benchmarks and control points</li>
                    <li>Setting out pile foundations</li>
                    <li>OGL (Original Ground Level) Survey</li>
                    <li>Pile As-Built surveys</li>
                    <li>EColumn and footing marking</li>
                    <li>Grid line marking</li>
                    <li>As-Built surveys of existing structures and during construction stages</li>
                    <li>Verticality checking</li>
                    <li>Comprehensive construction-related survey services</li>
                  </ul>
                  <p>VGS leverages advanced GIS and CAD mapping software,such as AutoCAD and CIVIL 3D, to extract and process detailed features efficiently.</p>
                </div>
                <div className="content-right">
                  {/* <img src={land_survey} alt="Land Surveying" /> */}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Dropdown section 2 */}
        <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(2)}>
            <h2>3D Laser Scanning Services</h2>
            <span className={`dropdown-arrow ${openDropdown === 2 ? "open" : ""}`}>&#9662;</span>
          </div>
          {openDropdown === 2 && (
            <div className="dropdown-content">
              <div className="dropdown-content-inner">
                <div className="content-left">
                  <ul>
                    <p>
                    Vidya Geomatics Solution (VGS) employs a full-time professional staff and utilizes cutting-edge 3D laser scanning technology to accurately capture the shape of objects using laser light, creating precise 3D models of geographic features. This advanced method offers numerous benefits, including Key services offered are:</p>
                    <li>Industrial Applications</li>
                    <li>Volume Calculations</li>
                    <li>Infrastructure Applications</li>
                    <li>Architecture Applications</li>
                    <li>Archaeological/Heritage</li>
                    <li>Energy</li>
                    <li>Registered Point Cloud</li>
                    <li>3D CAD Models</li>
                    <li>3D Mesh Models</li>
                    <li>BIM Models</li>
                    <li>2D CAD Documentation</li>
                    <li>Plant Design Models</li>
                    <li>Orthophotos</li>
                    <li>Deformation Surveys</li>
                    <li>Volume Survey</li>
                    <li>Clash Detection</li>
                    <li>3D Virtual Reality</li>
                    <li>Panoramic Images</li>
                    <li>Leica TruView</li>

                  </ul>
                </div>
                <div className="content-right">
                  {/* <img src={land_survey} alt="3D Laser Scanning Services" /> */}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Dropdown section 3 */}
        <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(3)}>
                <h2>As-Build Survey </h2>
             <span className={`dropdown-arrow ${openDropdown === 3 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 3 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <ul>
                    <p>
                    Vidya Geomatics Solution (VGS) employs a full-time professional staff skilled in 3D laser scanning and As-Built Total Station technology to accurately capture geographic features and monitor project progress through 2D and 3D models. This advanced method offers numerous benefits, including the following key services:</p>
                    <li>As-Built Survey of Existing Buildings</li>
                    <li>Produce Floor Plan Drawings</li>
                    <li>Produce Sectional Drawings</li>
                    <li>Produce Elevation Drawings</li>
                    <li>Produce Cloud Points</li>
                    <li>Produce 3D Model of the Building</li>
                    <li>BIM Modeling</li>
                    <li>AutoCAD Modeling</li>
                    
                  </ul>
                </div>
                <div className="content-right">
                  {/* <img src={land_survey} alt="Steel Works" /> */}
                </div>
              </div>
            </div>
          )}
        </div>

        
        {/* Dropdown section 4 */}
        {/* <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(4)}>
                <h2>Concrete Inspection Services</h2>
             <span className={`dropdown-arrow ${openDropdown === 4 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 4 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <ul>
                    <li>Locate rebars, tendons, metal</li>
                    <li>Locate plastic conduits, glass-fibre cables, voids etc.</li>
                    <li>Avoid hitting concealed objects when drilling or sawing</li>
                    <li>Checking of rebar and tendon layout (e.g. curvature, density, depth)</li>
                    <li>Inspection of floors, decks, slabs, balconies, etc in structures</li>
                    <li>Detection of voids and cavities</li>
                  
                  </ul>
                </div>
                <div className="content-right">
                  <img src={land_survey} alt="Steel Works" />
                </div>
              </div>
            </div>
          )}
        </div> */}

        
        {/* Dropdown section 5 */}
        {/* <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(5)}>
                <h2>Dimensional Survey Services</h2>
             <span className={`dropdown-arrow ${openDropdown === 5 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 5 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                
                  <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast</p>
                </div>
                <div className="content-right">
                  <img src={land_survey} alt="Steel Works" />
                </div>
              </div>
            </div>
          )}
        </div> */}


        
        {/* Dropdown section 6 */}
        {/* <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(6)}>
                <h2>Bathymatric Surveys</h2>
             <span className={`dropdown-arrow ${openDropdown === 6 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 6 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <ul>
                    <li>Hydrographic Surveys</li>
                    <li>Dredge Monitoring Survey</li>
                    <li>Volume Calculations</li>
                    <li>Charting & Contouring</li>
                    <li>Positioning Services</li>
                    <li>Pipeline / Cable Route Surveys</li>
                  </ul>
                </div>
                <div className="content-right">
                  <img src={land_survey} alt="Steel Works" />
                </div>
              </div>
            </div>
          )}
        </div> */}

        {/* Dropdown section 7 */}
        {/* <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(7)}>
                <h2>Geophysical Surveys</h2>
             <span className={`dropdown-arrow ${openDropdown === 7 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 7 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <ul>
                    <li>Seabed Mapping Surveys</li>
                    <li>Side Scan Surveys</li>
                    <li>Sub-Bottom Surveys</li>
                    <li>Magnetometer Surveys</li>
                    <li>Positioning Services</li>
                    <li>Pipeline / Cable Route Surveys</li>
                  </ul>
                </div>
                <div className="content-right">
                  <img src={land_survey} alt="Steel Works" />
                </div>
              </div>
            </div>
          )}
        </div> */}

        
        {/* Dropdown section 8 */}
        {/* <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(8)}>
                <h2>Mobile Mapping</h2>
             <span className={`dropdown-arrow ${openDropdown === 8 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 8 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <ul>
                    <li>Topographical Survey of Bulk / Huge areas</li>
                    <li>Road Survey for thousands of Kilometers</li>
                    <li>3D Mapping of Large Areas in a shortime</li>
                  </ul>
                </div>
                <div className="content-right">
                  <img src={land_survey} alt="Steel Works" />
                </div>
              </div>
            </div>
          )}
        </div> */}

          {/* Dropdown section 9 */}
          <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(9)}>
                <h2>Aerial / Drone Mapping</h2>
             <span className={`dropdown-arrow ${openDropdown === 9 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 9 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <ul>
                    <p>
                    VGS employs a full-time professional staff and state-of-the-art equipment, including a diverse fleet of UAVs, to provide a comprehensive range of aerial survey services. Their UAVs, such as the DJI RTK 300, Mavic 3 Enterprise, and Phantom 4 RTK, enable precise aerial drone mapping. Key services include:</p>
                    <li>Construction Update Survey</li>
                    <li>Mining & Aggregates</li>
                    <li>Solar Energy</li>
                    <li>Infrastructure Inspection</li>
                    <li>Roofing Inspection</li>
                    <li>Agriculture Inspections</li>
                    <li>Urban Planning & Land Management</li>
                    <li>Archaeology</li>
                    <li>Land Survey </li>
                  </ul>
                  <p>
                  DJI's advanced drone technology, including the Phantom 4 RTK, delivers centimeter-accurate data with fewer ground control points. The drones achieve 1cm+1ppm RTK horizontal positioning accuracy, 1.5cm+1ppm RTK vertical positioning accuracy, and a 5cm absolute horizontal accuracy of photogrammetric models when flying at 100m height with 2.7cm GSD on sunny days.</p>
                </div>
                <div className="content-right">
                  {/* <img src={land_survey} alt="Steel Works" /> */}
                </div>
              </div>
            </div>
          )}
        </div>

           {/* Dropdown section 10 */}
           {/* <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(10)}>
                <h2>Construction Surveys</h2>
             <span className={`dropdown-arrow ${openDropdown === 10 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 10 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <ul>
                    <li>Establishment of Construction Benchmarks / Control Points</li>
                    <li>Setting out of Construction Fence</li>
                    <li>Setting out Pile Foundation</li>
                    <li>Pile As-Built Survey</li>
                    <li>Building Boundary Setting out Survey</li>
                    <li>Column Marking, Footing Marking</li>
                    <li>Grid Line Marking</li>
                    <li>As-Built Survey of Existing Structures</li>
                    <li>As-Built Surveys during Construction Stages</li>
                    <li>Verticality Checking</li>
                    <li>Tie Beam level checking</li>
                    <li>All Construction Related Survey Services</li>
                  </ul>
                </div>
                <div className="content-right">
                  <img src={land_survey} alt="Steel Works" />
                </div>
              </div>
            </div>
          )}
        </div> */}

          
           {/* Dropdown section 11 */}
           <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(11)}>
                <h2>Geo Technical Investigation</h2>
             <span className={`dropdown-arrow ${openDropdown === 11 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 11 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <p>
                  VGS leverages its geotechnical engineering expertise to help clients save time, money, and resources. Capable of conducting fieldwork in even the most remote areas, VGS offers boring services that reach depths of 30-100 meters. The company provides client-specific reports with comprehensive data to support decision-making and project foundation design. Key services include:</p>
                  <ul>
                    <li>Geotechnical Investigation (Onshore/Offshore)</li>
                    <li>Chemical & Biological Laboratory</li>
                    <li>Physical & Mechanical Testing</li>
                    <li>Nondestructive Testing Services</li>
                    <li>Environmental Testing</li>
                    <li>Geophysical testing</li>
                    <li>Evaluation of the Existing Structures</li>
                    <li>Geological Mapping</li>
                    <li>Special Test Services</li>
                    <li>Oil Spill Monitoring</li>
                  </ul>
                  <p>VGS ensures the highest standards by utilizing the latest, most advanced field equipment and processing data with the industry's most powerful modeling software.</p>
                </div>
                <div className="content-right">
                  {/* <img src={land_survey} alt="Steel Works" /> */}
                </div>
              </div>
            </div>
          )}
        </div>

              {/* Dropdown section 12 */}
              <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(12)}>
                <h2>Tunnel Survey</h2>
             <span className={`dropdown-arrow ${openDropdown === 12 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 12 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <p>VGS employs a full-time professional staff to provide a comprehensive range of tunneling surveying services. These include tunnel profiling, traversing with triangulation methods, and wriggle survey report monitoring. Key services offered are:
                  </p>
                  <ul>
                    <li>Precise Alignment Control</li>
                    <li>Base Network Establishment</li>
                    <li>Support for Tunnel Boring Machine (TBM)</li>
                    <li>Deformation Monitoring</li>
                    <li> Cross-Sectional Measurements</li>
                    <li>Laser Scanning</li>
                    <li> Traverse and Triangulation</li>
                    <li>Staking Out and Setting Out</li>
                    <li>Integration with BIM</li>
                  </ul>
                  <p>VGS utilizes advanced software such as NRG, TBC, and Amberg tunneling software to deliver these services efficiently.</p>
                </div>
                <div className="content-right">
                  {/* <img src={land_survey} alt="Steel Works" /> */}
                </div>
              </div>
            </div>
          )}
        </div>


            {/* Dropdown section 13 */}
            <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(13)}>
                <h2>Track Surveying</h2>
             <span className={`dropdown-arrow ${openDropdown === 13 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 13 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <p>VGS employs a full-time professional staff to provide a comprehensive range of track surveying services. These include total station Digitel Level and Track Master Key services offered are:</p>
                  <ul>
                    <li>Alignment and Geometry Control</li>
                    <li>Establishing Control Points</li>
                    <li>Track Centerline Staking</li>
                    <li>Vertical and Horizontal Curve Measurement</li>
                    <li>Rail Gauge Verification</li>
                    <li>Super-Elevation and Cant Measurement</li>
                    <li>Trackbed Leveling and Grading</li>
                    <li>Switches and Crossings Turnout</li>
                    <li>Deformation and Settlement Monitoring</li>
                    <li>Final As-Built Survey</li>
                  </ul>
                  <p>VGS utilizes advanced software such as NRG, TBC, and Amberg leica software to deliver these services efficiently.</p>
                </div>
                <div className="content-right">
                  {/* <img src={land_survey} alt="Steel Works" /> */}
                </div>
              </div>
            </div>
          )}
        </div>

        
            {/* Dropdown section 14 */}
            {/* <div className="dropdown-container">
          <div className="dropdown-header" onClick={() => toggleDropdown(14)}>
                <h2>Meteorological Surveys</h2>
             <span className={`dropdown-arrow ${openDropdown === 14 ? "open" : ""}`}>&#9662;</span>
             </div>
             {openDropdown === 14 && (
             <div className="dropdown-content">
                 <div className="dropdown-content-inner">
                 <div className="content-left">
                  <ul>
                    <li>Ground Water Monitoring</li>
                    <li>Wheather Monitoring And Installations</li>
                    <li>Wheather Buoy Monitoring And Deployments</li>
                    <li>Water Quality Monitoring</li>
                    <li>Tidal Monitoring</li>
                  
                  </ul>
                </div>
                <div className="content-right">
                  <img src={land_survey} alt="Steel Works" />
                </div>
              </div>
            </div>
          )}
        </div> */}

      </div>
    </div>
  );
}

export default Portfolio;