import React from 'react';

//React-router-dom
import { NavLink } from 'react-router-dom';

//Styles
import './MobileNavigation.scss';
import logo from '../Img/logo.png';

function MobileNavigation(props){
    return(
        <div className="mobileMenu">
            <div className="container">
                {/* <h4>LOGO(Company Name)</h4> */}
                <img src = {logo} alt = 'logo' className='logo_comp'></img>

                <ul>
                    <li><NavLink to="/" exact activeClassName="active" onClick={() => props.closeMobileMenu()}>Home</NavLink></li>
                    <li><NavLink to="/about" exact activeClassName="active" onClick={() => props.closeMobileMenu()}>About Us</NavLink></li>
                    <li><NavLink to="/portfolio" exact activeClassName="active" onClick={() => props.closeMobileMenu()}>Services</NavLink></li>
                    <li><NavLink to="/client" exact activeClassName="active" onClick={() => props.closeMobileMenu()}>Client</NavLink></li>
                    <li><NavLink to="/rental" exact activeClassName="active" onClick={() => props.closeMobileMenu()}>Rental Service</NavLink></li>
                    <li><NavLink to="/quotation" exact activeClassName="active" onClick={() => props.closeMobileMenu()}>Quotation</NavLink></li>
                    <li><NavLink to="/contact" exact activeClassName="active" onClick={() => props.closeMobileMenu()}>Contact Us</NavLink></li>
                </ul>
            </div>

        </div>
    )
}

export default MobileNavigation;