import React from 'react';

//Styles
import './About.scss';

//Icons
// import Building from '../Icons/about/Building';
// import Excavator from '../Icons/about/Excavator';
// import Wrench from '../Icons/about/Wrench';
// import Painter from '../Icons/about/Painter';
// import Spray from '../Icons/about/Spray';
// import Shield from '../Icons/about/Shield';
import Standard1 from '../Icons/about/Standard1';
import Standard2 from '../Icons/about/Standard2';
import Standard3 from '../Icons/about/Standard3';
import Standard4 from '../Icons/about/Standard4';

//SVG icons
import Dots from '../Icons/Dots';
// import Square from '../Icons/Square';
// import Circle from '../Icons/Circle';
// import Triangle from '../Icons/Triangle';

function About() {
    return (
        <div className="aboutView">
            <div className="container">
                <div className="aboutUs section">
                    <h2>About<Dots /></h2>

                    <div className="container-inner">
                        <h4>Vidyageomatics</h4>

                        <div className="text">
                            <p><span>VIDYA GEOMATICS SOLUTION</span> the most leading Organization in the field of Land Survey, Mobile Mapping solutions, Drone mapping and laser scanning solutions</p>

                            <p><span>VIDYA GEOMATICS SOLUTION</span> is a pioneering Survey Engineering Company in <span>INDIA</span> established in the year of 2019, <span>VIDYA GEOMATICS SOLUTION</span> Survey Engineering built on a strong work ethic, honesty and a commitment to relationships. Today <span>VIDYA GEOMATICS SOLUTION</span> is one of the leading organizations in the field of Land Surveying, Underground Surveying, 3D Laser Scanning Surveying, GIS as well as Geographic Surveys which has International Standard and Quality. We have achieved a solid reputation for providing conscientious and reliable engineering survey solutions to a wide range of infrastructure, construction and development projects for Clients, Government Sectors, Consultants & Constructions Companies in India With more than 6 years of experience,<span>VIDYA GEOMATICS SOLUTION</span>continues to improve its heritage by strengthening its dedication & sincerity to each & everyone</p>

                            <p></p>

                            <p>Join us as we continue to shape the skyline and landscapes of tomorrow, delivering projects that stand as a testament to our commitment to quality, innovation, and customer satisfaction.</p>
                        </div>
                    </div>

                    
                </div>
{/* 
                <div className="whatWeDo section">
                    <h2>What we do <Dots /></h2>

                    <div className="container-inner">
                        <div className="card-container">
                            <div className="card">
                                <Building />
                                <p>
                                    Implementation and reconstruction of buildings</p>
                            </div>

                            <div className="card">
                                <Excavator />
                                <p>Preparatory, ground and demolition works</p>
                            </div>

                            <div className="card">
                                <Wrench />
                                <p>Rehabilitation and repair of engineering structures</p>
                            </div>

                            <div className="card">
                                <Painter />
                                <p>Removal of graphite and anti-graphite coatings</p>
                            </div>

                            <div className="card">
                                <Spray />
                                <p>Waterproofing sprays</p>
                            </div>

                            <div className="card">
                                <Shield />
                                <p>Comprehensive anti-corrosion protection</p>
                            </div>
                        </div>
                    </div>

                    
                </div> */}

                <div className="ourStandards section">
                    <h2>Why you choose us <Dots /> </h2>

                    <div className="container-inner">
                        <div className="text">
                            <p>In every aspect of our operations, we take proactive measures to minimize waste, implement environmentally friendly practices, and ensure the highest standards of health and safety. 
                            </p>
                        </div>

                        <div className="standard-container">
                            <div className="standard">
                                <Standard1 />
                                <div className="standard-text">
                                    <h4>Great services</h4>
                                    <p>You can rely on our commitment to delivering top-quality work, on time, and within budget.</p>
                                </div>
                            </div>

                            <div className="standard">
                                <Standard2 />
                                <div className="standard-text">
                                    <h4>Honesty, Integrity and Trust</h4>
                                    <p>This is integral to every practice and decision in the business. Our reputation depends on it.</p>
                                </div>
                            </div>

                            <div className="standard">
                                <Standard3 />
                                <div className="standard-text">
                                    <h4>Professionals Approach</h4>
                                    <p>We operate worldwide and our access to skilled and specialist resource allows us to respond effectively no matter the size of the project or how challenging.</p>
                                </div>
                            </div>

                            <div className="standard">
                                <Standard4 />
                                <div className="standard-text">
                                    <h4>Innovation</h4>
                                    <p>Our innovation is what sets us apart, whether we are offering a unique product or challenging or methology to become more effective.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Add Mission and Vision Section */}
                <div className="mission-vision section">
                    <div className="mission">
                        <h4>Our Mission</h4>
                        <p>VIDYA GEOMATICS SOLUTION aims to become the customers' most preferred choice by achieving excellence in quality and delivering value-added projects on time. The company is committed to providing the highest level of service in the construction industry, offering superior craftsmanship in every project it undertakes.</p>
                    </div>
                    <div className="vision">
                        <h4>Our Vision</h4>
                        <p>
                        VIDYA GEOMATICS SOLUTION aims to establish itself as a leader in the
                        local construction industry. With a strong commitment to our client and our craft, we are dedicated to delivering the quality work you
                        deserve while helping you reduce costs.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
