import React from "react";
import './Client.scss';
import indianoil from '../Img/client/indian-oil.png';
import amberg from '../Img/client/amberg.png';
import lt from '../Img/client/L-T.png';
import vedanta from '../Img/client/vedanta.png';
import railway from '../Img/client/railway.png';
import nvda from '../Img/client/nvda.png';
import ashoka from '../Img/client/ashoka.png';
import pacific from '../Img/client/pacific.png';
import patel from '../Img/client/patel.png';
import cds from '../Img/client/cds.png';

function Client() {
    return (
        <div className="client-container">
            <div className="client-slider">
                <div className="client-slider-container">
                    <div className="client-slider-text">
                        <h2>Partnering with industry leaders to deliver</h2>
                        <h3>
                            excellence , innovation
                        </h3>
                    </div>
                </div>
            </div>
            <div className="recent-client">
            <div className="recent-client-head">Our Recent Clients</div>
            <div className="recent-client-track">
                <img src={indianoil} alt="Indian Oil" />
                <img src={vedanta} alt="Vedanta" />
                <img src={lt} alt="L&T" />
                <img src={amberg} alt="Amberg" />
                <img src={railway} alt="Railway" />
                <img src={nvda} alt="nvda" />
                <img src={ashoka} alt="ashoka" />
                <img src={pacific} alt="pacific" />
                <img src={patel} alt="patel" />
                <img src={cds} alt="cds" />
                {/* Repeat the images again for smooth looping */}
                <img src={indianoil} alt="Indian Oil" />
                <img src={vedanta} alt="Vedanta" />
                <img src={lt} alt="L&T" />
                <img src={amberg} alt="Amberg" />
                <img src={railway} alt="Railway" />
                <img src={nvda} alt="nvda" />
                <img src={ashoka} alt="ashoka" />
                <img src={pacific} alt="pacific" />
                <img src={patel} alt="patel" />
                <img src={cds} alt="cds" />
                
            </div>
        </div>
        </div>
    );
}

export default Client;
